import { SVGProps } from "react";

export default function ShareIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5059_1969)">
        <path
          d="M18.2256 14.1806V15.6077C18.2256 17.3301 16.8313 18.7245 15.1089 18.7245H8.87546C7.15306 18.7245 5.75874 17.3301 5.75874 15.6077V9.3907C5.75874 7.6683 7.15306 6.27398 8.87546 6.27398H10.2862C10.237 6.01152 10.1878 5.76546 10.1878 5.4866C10.1878 5.20774 10.2206 4.96168 10.2862 4.69922H8.87546C6.30006 4.69922 4.20038 6.7989 4.20038 9.3907V15.6077C4.20038 18.1995 6.30006 20.2828 8.87546 20.2828H15.0925C17.6843 20.2828 19.7676 18.1831 19.7676 15.6077V14.197C19.5379 14.2462 19.2755 14.2954 18.9966 14.2954C18.7177 14.2954 18.4717 14.2462 18.2256 14.1806Z"
          fill="#757575"
          fillOpacity="0.8"
        />
        <path
          d="M18.9967 4.69922H13.1242C12.6977 4.69922 12.3368 5.0437 12.3368 5.4866C12.3368 5.9295 12.6813 6.27398 13.1242 6.27398H17.1103L9.13805 14.2298C8.82638 14.5415 8.82638 15.0336 9.13805 15.3289C9.28568 15.4765 9.48253 15.5585 9.69578 15.5585C9.89263 15.5585 10.0895 15.4765 10.2535 15.3289L18.2257 7.35663V11.3428C18.2257 11.7693 18.5702 12.1301 19.0131 12.1301C19.456 12.1301 19.8005 11.7857 19.8005 11.3428V5.4866C19.7841 5.0437 19.4396 4.69922 18.9967 4.69922Z"
          fill="#757575"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_5059_1969">
          <rect width="19.2" height="19.2" fill="white" transform="translate(2.39999 2.90039)" />
        </clipPath>
      </defs>
    </svg>

    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="16"
    //   height="16"
    //   viewBox="0 0 16 16"
    //   fill="none"
    //   {...props}
    // >
    //   <g clipPath="url(#clip0_9297_1386)">
    //     <path
    //       d="M12.0005 10.7199C11.4938 10.7199 11.0405 10.9199 10.6938 11.2333L5.94049 8.46658C5.97382 8.31325 6.00049 8.15992 6.00049 7.99992C6.00049 7.83992 5.97382 7.68658 5.94049 7.53325L10.6405 4.79325C11.0005 5.12659 11.4738 5.33325 12.0005 5.33325C13.1072 5.33325 14.0005 4.43992 14.0005 3.33325C14.0005 2.22659 13.1072 1.33325 12.0005 1.33325C10.8938 1.33325 10.0005 2.22659 10.0005 3.33325C10.0005 3.49325 10.0272 3.64658 10.0605 3.79992L5.36049 6.53992C5.00049 6.20659 4.52715 5.99992 4.00049 5.99992C2.89382 5.99992 2.00049 6.89325 2.00049 7.99992C2.00049 9.10659 2.89382 9.99992 4.00049 9.99992C4.52715 9.99992 5.00049 9.79325 5.36049 9.45992L10.1072 12.2333C10.0738 12.3733 10.0538 12.5199 10.0538 12.6666C10.0538 13.7399 10.9272 14.6133 12.0005 14.6133C13.0738 14.6133 13.9472 13.7399 13.9472 12.6666C13.9472 11.5933 13.0738 10.7199 12.0005 10.7199Z"
    //       fill="currentColor"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_9297_1386">
    //       <rect width="16" height="16" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
}
