const tooltips = [
  "Our reports are rigorously fact-checked page by page for absolute precision.",
  "Did you know? Perceive Now’s reports save an average of 40 hours of analysis per project.",
  "Pro Tip: Use dynamic section customization to tailor your insights exactly how you need them - every single time!",
  "New! Multi-layered explainability is now live in our reports—check it out!",
  "Try it now: “Make your reports speak to every stakeholder with Quick-View multi-tone summaries.",
  "New Feature Alert: Edit and add your own data sources for truly personalized insights!",
  "Did you know? This report would typically take 6 to 8 weeks for conventional firms.",
  "By choosing us, you’ve saved approximately $35,000 in traditional market research costs.",
  "Traditional market research can cost between $20,000 and $60,000; with Perceive Now, you’re investing smarter.",
];

export default tooltips;
